import {createStore as reduxCreateStore} from 'redux';

//action
export const toggleModal = (isModalShow) => ({
  type: 'TOGGLE_MODAL',
  isModalShow
});

export const toggleModalSize = (modalSize) => ({
  type: 'TOGGLE_MODALSIZE',
  modalSize
});

export const toggleModalContent= (modalContent) => ({
  type: 'TOGGLE_MODALCONTENT',
  modalContent
});

export const toggleModalTitle= (modalTitle) => ({
  type: 'TOGGLE_MODALTITLE',
  modalTitle
});

export const toggleModalType= (modalType) => ({
  type: 'TOGGLE_MODALTYPE',
  modalType
});


// reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL': {
      return {...state, isModalShow: action.isModalShow};
    }
    case 'TOGGLE_MODALSIZE': {
      return {...state, modalSize: action.modalSize};
    }
    case 'TOGGLE_MODALCONTENT': {
      return {...state, modalContent: action.modalContent};
    }
    case 'TOGGLE_MODALTITLE': {
      return {...state, modalTitle: action.modalTitle};
    }
    case 'TOGGLE_MODALTYPE': {
      return {...state, modalType: action.modalType};
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  isModalShow: false,
  modalContent: '',
  modalSize: '',
  modalTitle: '',
  modalType: ''
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
